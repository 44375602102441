import React, {Dispatch} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {store} from './store/store';
import {Provider} from 'react-redux';
import theme from './theme';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import config from './config';
import {init as initIosHacks} from './utilities/ios-hacks';
import {EnhancedStore} from '@reduxjs/toolkit';
import {fetchAllVenues, fetchCuisines} from './store/venuesSlice';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import {loadState} from './store/manage-history';
import { setupGeolocation } from './store/geolocationSlice';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import {initPlatformCookie, setFirstTimeUser, sleep} from './utilities/misc-utilities';
import { v4 as uuid } from 'uuid';
import {
    getAccessStatus,
    getAnonymousUserDetails,
    getPlatform,
    setAnonymousUserDetails
} from './utilities/CookieManagement';
import {sendAmplitudeData, setAmplitudeUserId} from './utilities/amplitude';
import {PageRouter} from './PageRouter';
import {PhoneFrame} from './PhoneFrame';

const startupInfo = ('Version: ' + process.env.REACT_APP_GIT_COMMIT || process.env.CF_PAGES_COMMIT_SHA) +
    (' Env: ' + config.env || 'Unknown');

console.info('App starting up. ' + startupInfo);

Sentry.init({
    dsn: 'https://1665ac0a5dda4ccf849d9e767743bcef@o525659.ingest.sentry.io/5640140',
    integrations: [new Integrations.BrowserTracing()],
    release: process.env.REACT_APP_GIT_COMMIT,
    environment: config.env,

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.025,
});

window.onpopstate = () => loadState(store);
initIosHacks();
loadInitialData(store);
initPlatformCookie();
initSessionCookies();

initPlatform().then(() => {
    loadGeolocation(store);
}).catch((e) => {
    console.error('Platform init failed: ' + e);
});
loadState(store);

console.info('Running on platform = ' + getPlatform());

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <PhoneFrame>
                    <PageRouter/>
                </PhoneFrame>
            </ThemeProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

function loadInitialData(store: EnhancedStore) {
    const dispatch = store.dispatch as Dispatch<unknown>;
    dispatch(fetchAllVenues());
    dispatch(fetchCuisines());
}

function loadGeolocation(store: EnhancedStore) {
    const dispatch = store.dispatch as Dispatch<unknown>;
    dispatch(setupGeolocation());
}

function serviceWorkerRegistrationOnSuccess() {
    console.log('Service worker registered. ' + startupInfo);
}

function serviceWorkerRegistrationOnUpdate() {
    console.log('Service worker updated. ' + startupInfo);
}

serviceWorkerRegistration.register({
    onSuccess: serviceWorkerRegistrationOnSuccess,
    onUpdate: serviceWorkerRegistrationOnUpdate
});

function initPlatform(): Promise<void> {
    const platformInitPromise: Promise<void> = new Promise((resolve) => {
        const platform = getPlatform();

        if (platform === 'Cordova') {
            document.addEventListener('deviceready', () => {resolve();}, false);
            const scriptTag = document.createElement('script');
            scriptTag.src = './cordova/cordova.js';

            document.body.appendChild(scriptTag);
        } else {
            resolve();
        }
    });

    const timeoutPromise = sleep(10000).then(() => {
        return Promise.reject('timeout');
    });
    
    return Promise.race([platformInitPromise, timeoutPromise]);
}

function initSessionCookies() {
    const anonymousUserDetails = getAnonymousUserDetails();
    const oldAccessStatus = getAccessStatus();

    if (anonymousUserDetails) {
        setAmplitudeUserId(anonymousUserDetails.id);

        sendAmplitudeData(
            'existingUserPageLoad', {anonymousUserId: anonymousUserDetails.id}
        );

        setFirstTimeUser(false);
    } else if (oldAccessStatus.code) {
        const newAnonymousId = oldAccessStatus.code;
        setAnonymousUserDetails({
            id: newAnonymousId
        });
        setAmplitudeUserId(newAnonymousId);

        sendAmplitudeData(
            'legacyUserMigrationPageLoad', {anonymousUserId: newAnonymousId}
        );
        setFirstTimeUser(false);
    } else {
        const newAnonymousId = uuid();
        setAnonymousUserDetails({
            id: newAnonymousId
        });
        setAmplitudeUserId(newAnonymousId);

        sendAmplitudeData(
            'newUserPageLoad', {anonymousUserId: newAnonymousId}
        );
        setFirstTimeUser(true);
    }
}